@media print {

  @page {
    margin: 3cm;
  }

  .print-me {
    display: block;
  }

  .do-not-print {
    display: none;
  }

  [id^=liveagent] {
    display: none !important;
  }

  html {
    font-size: 12pt;
  }

  body {
    color: #000 !important;
  }

  .container {
    width: 100%;
  }

  img {
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
  }

  h1,
  .blog-post .page-title,
  .single-case-study .case-study-header h1,
  .introducing-magic-jack-video h1,
  .premium-page .blue-bg h1 {
    font-size: 26pt;
    font-weight: 500;
    color: #000;
  }

  h2 {
    font-size: 22pt;
  }

  h3,
  .price-comparison h3,
  .post-content h3 {
    font-size: 18pt;
    color: #000;
  }

  h4,
  .price-comparison h4 {
    font-size: 14pt;
    color: #000;
  }

  .col-md-3 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%;
  }

  .col-md-6 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%;
  }

  .col-lg-5 {
    width: 41.66667%;
  }

  .col-lg-7 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 58.33333%;
            flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }

  .col-lg-10 {
    width: 83.33333%;
  }

  h1, h2, h3, h4, h5, h6 {
      color: #000;
      font-weight: 500;
  }

  .lead {
    font-size: 14pt;
  }

  a,
  .card-title a {
      color: #000;
  }

  .nav-pills .nav-link {
    color: #000;
    &.active {
      color: #000;
    }
    &:before {
        background: #000;
    }
  }
  /*a {
      font-weight: bolder;
      text-decoration: none;
   }

   a[href]:after {
      content:" <" attr(href) "> ";
   }*/
   .icon.black-logo {
      height: 36px;
  }

  .interior-page {
     padding-top: 10px;
      .page-title {
        font-size: 22pt;
        margin-top: 0;
      }
  }

  .btn-secondary {
    color: #000;
    border: 2px solid #000;
    background-color: #FFF;
  }

  #homepage {

    .hero {
      &.home-hero {
          height: auto;
          background: none !important;
          margin-bottom: 0;

          .columns {
              display: block;
              text-align: center;
              padding-top: 2rem;
              padding-bottom: 2rem;
              color: #000;
          }

          .offer-terms,
          .offer-details,
          .offer-button,
          .offer-footnote {
            max-width: 100%;

            span {
                display: inline-block;
                vertical-align: baseline;
            }

            .dollars {
                font-size: 20pt;
                position: relative;
                top: auto;
                vertical-align: text-bottom;
            }

            .value {
                font-size: 40pt;
                line-height: 1;
                margin-left: 0;
                margin-right: 0;
            }

            .terms {
                font-size: 18pt;
                width: auto;
                display: inline-block;
                line-height: 1;
            }
          }

            .offer-footnote {
              position: relative;
              bottom: auto;
            }

          .offer-button .ghost-button {
              /*display: inline-block;
              color: #000;
              background-color: #fff;
              border: none;
              padding: .5em 0 0.4em;
              margin-top: 30px;*/
              display: none;
          }
      }
    }
  }

  .blue-bkg {
      color: #000;
      background-color: #FFF;

      h1, h2, h3, h4, h5, h6 {
          color: #000;
      }
  }

  .overview {
      padding: 0;
  }

  .pricing-container-plain {
    .btn {
        display: none;
    }
    .pricing-container {
      .premium {
           padding-top: 0;
      }
      .home-and-office .cost,
      .home-and-office h3,
      .premium .cost,
      .premium h3 {
          color: #000;
      }
      h3 {
        font-size: 22pt;
      }
      .pricing {
        .cost {
          font-size: 26pt;
        }
        span {
          display: inline-block;
          vertical-align: middle;
          color: #000;
        }
      }
    }
  }

  .product-callout {
    page-break-before: always;
    color: #000;
    background: none;

      .image-wrap {
          -webkit-box-flex: 0;
              -ms-flex: 0 0 41.66667%;
                  flex: 0 0 41.66667%;
          max-width: 41.66667%;
          -webkit-box-ordinal-group: 1;
              -ms-flex-order: 0;
                  order: 0;
      }

      .text-wrap {
          -webkit-box-flex: 0;
              -ms-flex: 0 0 58.33333%;
                  flex: 0 0 58.33333%;
          max-width: 58.33333%;
      }

    .product-intro {
      color: #000;
    }
    .btn-primary {
        display: none;
    }
  }

  .highlight {
    page-break-before: always;
    .col-md-3 {
        -webkit-box-flex: 0;
            -ms-flex: 0 0 50%;
                flex: 0 0 50%;
        max-width: 50%;
    }
    &.white-bkg svg {
      fill: #000;
      width: 100px;
      height: 100px;
    }
    .btn {
      display: none;
    }
  }

  #pricing {
      section.hero {
        background: none !important;
        height: auto;
        .holder {
          padding-top: 0;
          h1 {
            color: #000;
            span.price-wrap {
                font-size: 12px;
            }
            span.sub-text {
                font-size: 18px;
            }
          }
          .info h2 {
              color: #000;
              font-size: 22pt;
          }
          p {
            color: #000;
          }
        }
      }

    .plan_pricing {
      margin-top: 1cm;
      .inner {
        padding-bottom: 0;
      }

      .row-x {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-orient: horizontal;
          -webkit-box-direction: normal;
              -ms-flex-direction: row;
                  flex-direction: row;
          -ms-flex-wrap: nowrap;
              flex-wrap: nowrap;
          -webkit-box-pack: center;
              -ms-flex-pack: center;
                  justify-content: center;
      }

      .products {
        width: 9cm;
      }

      .products-home-and-office .product-wrap,
      .products-premium .product-wrap {
          background: none;
          color: #000;
      }
      .products h2 {
        color: #000;
        background: none;
        font-size: 22pt;
        line-height: 1rem;
      }
      .products-premium h2 {
        background: none;
      }
      p.price {
          font-size: 26pt;
      }
      .feature-list {
        margin-top: 0;
        font-size: 13pt;
        li {
          padding: 0;
        }
      }
      .row-x a.button.white {
          display: none;
      }
      .learn-more-link a {
          display: none;
      }
    }
    .plan-blurb {
        margin: 0;
    }
  }

  .plan-comparison {
    /*page-break-before: always;*/

    .container {
      width: 100%;
    }
    .header-row h3 {
        color: #000;
    }
    .row .col-md-3 {
        -webkit-box-ordinal-group: 3;
            -ms-flex-order: 2;
                order: 2;
    }
    .row .line-title {
        /*flex: 0 0 30%;*/
        -webkit-box-ordinal-group: 1;
            -ms-flex-order: 0;
                order: 0;
        font-size: 12pt;
    }
    .section-title h2 {
      color: #000;
    }
    .circle.blue,
    .circle.purple {
        background-color: #fff;
        /*border: 2px solid #000;*/
        &:after {
           content:  "\2713 ";
        }
    }
    .circle.blue.coming-soon-circle,
    .circle.purple.coming-soon-circle {
        border: none;
    }
    .row.coming-soon.header {
        color: #000;
    }

  }

  .rates {
      page-break-before: always;
      padding-top: 0;
      background: none;
      .col-md-10 {
          -webkit-box-flex: 0;
              -ms-flex: 0 0 100%;
                  flex: 0 0 100%;
          max-width: 100%;
      }

      h2, h3 {
        margin-top: 0;
        color: #000;
        font-size: 22pt;
      }
      h3 {
        font-size: 18pt;
      }
      .container {
        background: none;
      }
      .rates p {
        color: #000;
      }
      .int-rates {
        .inner {
          border: 1px solid #000;
          h4 {
              margin-bottom: 0;
              padding-bottom: 0;
              background: none;
              color: #000;
          }
          p {
            color: #000;
            font-size: 20pt;
            &:last-of-type {
              color: #000;
            }
          }
        }
      }
      a.button.center {
        background: none;
        border: 1px solid #000;
        color: #000;
        &[href]:after {
          content:" <https://www.magicjackforbusiness.com" attr(href) "> ";
          width: auto;
          text-transform: none;
          display: block;
        }
      }
  }

  .contact-sales {
    #sales-hours {
      display: block !important;
      .hidden-md-up {
        display: none !important;
      }
      .hidden-sm-down {
        display: block !important;
      }
      .section-divider {
          display: none;
      }
      .telephone {
          color: #000;
      }
    }
    h2,
    h3,
    .marketo-form {
      display: none !important;
    }
  }

  #savings {
    .savings-bars {
      .bar {
        background-color: #FFF;
        border: solid 2px #000;
        color: #000;
      }
      .bar.light {
        background-color: #FFF;
      }
      .bar.medium {
        background-color: #FFF;
      }
    }

  }

  .competitor-comparison {
    .header-row {
      background-color: #FFF;
      border-top: 1px solid #000;
      border-bottom: 1px solid #000;
      h3 {
        color: #000;
      }
    }
    .competitor-comparison-table {
      .row {
        border-bottom: 1px solid #000;
      }
      .small-12 {
          background: #ccc;
      }
      .magicjack {
          background-color: #e5e5e5;
      }
      div[class*="column"] {
          color: #000;
      }
      .circle {
        background-color: #FFF;
        border: 2px solid #000;
      }
    }
  }

  #features {
    .features-hero {
        height: auto;
        background: none;
        padding-top: 0;
        margin-top: 0;
        &:after {
          background-color:transparent;
        }
        .page-title {
             color: #000;
        }
    }
    .main {
        width: 100%;
    }
    #features-container {
      .feature-item {
        border-bottom: 1px solid #000;
        padding: 10px 0;
      }
      .index-sidebar {
          display: none;
      }
    }
    .tag-default {
        background-color: #FFF;
        color: #000;
        border: 1px solid #000;
    }
    #features-nav {
        /*display: none;*/
    }

    #featureCollapse2 .card {
      page-break-after: always;
    }
    .flex-items-xs-middle .col-md:first-child {
      width: 50%;
      margin-bottom: 18pt;
    }

  }

  #support {
    .container.wrap {
      display: none;
    }
    .main.two-thirds {
        width: 100%;
    }
    .card {
        page-break-inside: avoid;
        border: 1px solid #000;
    }
    .card-header {
      /*border-bottom: 1px solid #000;*/
      h5 a {
        color: #000;
      }
    }

  }

  #documentation {
    .phone-card .resource-item .resource-link a {
        color: #000;
    }
    #file_pdf {
      fill:#000 !important;
    }
  }

  #case-studies {

    .case-study {
      .company-logo {
            height: 150px !important;
            width: 150px;
            margin: 0 auto;
        .image {
          -webkit-filter: grayscale(1);
                  filter: grayscale(1);
        }
      }
      .company-name a {
        color: #000;
      }
    }
    .btn-secondary[href]:after {
      content:" <www.magicjackforbusiness.com" attr(href) "> ";
      display: block;
      text-transform: none;
      overflow-wrap: break-word;
      word-wrap: break-word;
      word-break: break-all;
      word-break: break-word;
    }
    .col-lg-3 {
        page-break-after: always;
        -webkit-box-flex: 0;
            -ms-flex: 0 0 50%;
                flex: 0 0 50%;
        max-width: 50%;
    }
  }

  #blog {
    .container {
      width: 80%;
    }
    .blog-posts {
      .grid-item {
        /*page-break-after: always;*/
        -webkit-box-flex: 0;
            -ms-flex: 0 0 100%;
                flex: 0 0 100%;
        max-width: 100%;
        width: 100%;
        position: relative !important;
        left: auto !important;
        top: auto !important;
        .card {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-orient: horizontal;
          -webkit-box-direction: normal;
              -ms-flex-direction: row;
                  flex-direction: row;
          /*page-break-inside: avoid;*/
          .card-block {
              padding: 0;
              width: 100%;
          }
        }
      }
      .card-img-top {
        -webkit-filter: grayscale(1);
                filter: grayscale(1);
        width: 40%;
        height: 160px;
        margin-right: 2%;
      }
      .btn[href]:after {
        content:" <www.magicjackforbusiness.com" attr(href) "> ";
        display: block;
        text-transform: none;
        overflow-wrap: break-word;
        word-wrap: break-word;
        word-break: break-all;
        word-break: break-word;
      }
    }

  }

  .blog-post {
    .blog-post-header {
        padding-top: 0;
        padding-bottom: 0;
    }
    .image-container {
        width: 50%;
        margin: 0 auto;
    }
    .pull-right {
        max-width: 50%;
        max-height: 300px;
        margin-right: 0;
        float: right;
        margin-left: 35px;
        margin-top: 20px;
        margin-bottom: 20px;
    }
    .pull-left {
        max-width: 50%;
        max-height: 300px;
        margin-left: 0;
        float: left;
        margin-top: 20px;
        margin-right: 35px;
        margin-bottom: 20px;
    }
    .share-page,
    .related-posts,
    .prev-next-navigation {
        display: none;
    }
  }

  .single-case-study {
    .case-study-header {
        padding: 0;
        div {
          display: block;
          text-align: center;
          img {
            margin: 1cm auto;
            max-height: 70px;
          }
        }
        .col-md-7,
        .col-md-5 {
            -webkit-box-flex: 0;
                -ms-flex: 0 0 100%;
                    flex: 0 0 100%;
            max-width: 100%;
        }
    }
    .video-container {
      .col-lg-6 {
          -webkit-box-flex: 0;
              -ms-flex: 0 0 50%;
                  flex: 0 0 50%;
          max-width: 50%;
      }
    }
    .features-link {
      display: none;
    }
    .pull-right {
        max-width: 50%;
        max-height: 300px;
        margin-right: 0;
        float: right;
        margin-left: 35px;
        margin-top: 20px;
        margin-bottom: 20px;
    }
    .pull-left {
        max-width: 50%;
        max-height: 300px;
        margin-left: 0;
        float: left;
        margin-top: 20px;
        margin-right: 35px;
        margin-bottom: 20px;
    }
    .case-study-content {
      blockquote {
          color: #000;
          padding: 12px;
          padding-bottom: 0;
          border-color: #000;
      }
    }

  }

  #about {
    .management-container {
        padding: 20px 0 0;
        margin-bottom: 0;
        page-break-before: always;
    }
    .profile-container {
      div[class*=col-] {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          width: 100%;
          max-width: 100%;
          -webkit-box-flex: 0;
              -ms-flex: 0 0 100%;
                  flex: 0 0 100%;
          padding: 0 0 10px;
          margin-bottom: 40px;
          border-bottom: 1px solid #000;
      }
      .card {
        border: none;
        width: 30%;
        margin-bottom: 0;
        .card-block {
          display: none;
        }
      }
      .mfp-hide {
        display: block !important;
      }
      .white-popup {
          page-break-inside: avoid;
          position: relative;
          background: #FFF;
          padding: 0 0 0 30px;
          width: 800px;
          max-width: 90%;
          margin: 0 auto;
          .job-title {
              font-size: 12pt;
          }
          p {
              font-size: 11pt;
          }
      }
      .btn {
        display: none;
      }
    }
  }
  
  #sitemap {
    .col-md-6 {
        -webkit-box-flex: 0;
            -ms-flex: 0 0 100%;
                flex: 0 0 100%;
        max-width: 100%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
        .sitemap-item {
            width: 48%;
            margin: 0 1% .5cm;
        }
    }
  }

  #home-and-office {
    .interior-page .page-description {
        margin-bottom: 24px;
    }
    .introducing-magic-jack-video {
      margin-top: 0;
      padding: 0;
      background-color: #FFF;
      p {
        color: #000;
      }
      .int-block {
        ul {
          color: #000;
        }
        .btn {
          display: none;
        }
      }
    }

  }

  #premium {
    .col-lg-10 {
        width: 100%;
    }
    .premium-page .blue-bg {
        background: #FFF;
        color: #000;
        padding: 0;
        p {
          font-weight: normal;
          margin-bottom: .125cm;
        }
        .btn-primary {
          display: none;
        }
    }
  }

  .features-interior-page {
    .features-link {
      display: none;
    }

  }
  symbol#icon-home-and-office-badge .badge-ico-0,
  symbol#icon-premium-badge .badge-ico-1,
  symbol#icon-coming-soon-badge .badge-ico-2 {
    fill: #000;
  }

  #terms p,
  #legal p,
  #e911 p {
    font-size: 10pt;
  }

  #international-voip-calls {
    .rates-search-container {
      display: none;
    }
  }

  .single-testimonial {
    .customer-image {
        width: 160px;
    }
    .testimonial-container {
        padding: 20px 0;
        margin-left: 0;
    }
    .testimonial-container img {
        max-width: 30%;
    }
    blockquote {
        color: #000;
        padding: 10px;
        font-size: 12pt;
        margin: 30px 20px;
        border: 1px solid #000;
    }
      .btn[href]:after {
        content:" <www.magicjackforbusiness.com" attr(href) "> ";
        display: block;
        text-transform: none;
        overflow-wrap: break-word;
        word-wrap: break-word;
        word-break: break-all;
        word-break: break-word;
      }
  }


} //end media print


/*@media print and (color) {
   * {
      -webkit-print-color-adjust: exact;
      print-color-adjust: exact;
   }
}*/